/* @font-face {
  font-family: Retro;
  src: url(AmericanPurpose-109L.ttf);
  unicode-range: U+000-5FF;
} */

* {
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px;
  }

  html { 
    font-size: calc(1em + 1vw) 
  }
  
  body {
    /* background: yellow; */
    background-color: #FFF7E8;
    padding: 0;
    margin: 0;
    /* height: 100vh;
    width: 100vw; */
    overflow-x: hidden;
  }
  
  #app {
    /* background: yellow; */
    /* align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh; */
    position: relative;
  }
  
/* LOG IN PAGE STYLES */

#logInContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* min-width: 800px;
  min-height: 600px; */
  overflow: hidden
}

#logInSVGContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

#logInLogoContainer {
  /* background-color: black; */
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
}

#lineContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-around;
}

.lines {
  width: 0%;
  height: 2% ;
  background-color: black;
}

#logInLogo {
  height: 90%;
}

#splashContainer{
  position: absolute;
  height:100%;
  width: 100%;
}

.splashCircle {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  visibility: hidden;
}

#spotifyLogInButton {
  /* background-color: black; */
  background-color: black;
  /* width: 135px; */
  width: 150px;
  height: 35px;
  border-radius: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: left;
  bottom: 0;
  right: 0;
  margin: 10px;
  z-index: 5;
  padding: 4px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: none;
  cursor: pointer;
}

#spotifyLogInButton h1 {
  font-size: 17px;
  color: #1ED760;
  margin-left: 5px;
}

a {
  text-decoration: none;
  color: black;
}

/* END LOG IN PAGE STYLES */

#homeComponentContainer {
  /* background-color: #FFFBF2;; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  min-height: 600px;
  /* background-image: url('../public/date-bg.png');
  background-position: center;
  background-size: cover; */
  /* min-width: 400px; */
}

#header {
  /* background: rgba(129, 25, 155, 1);; */
  background-color: #D60404;
  height: 100px;
  width: 100%;
  top: 0;
  display: flex;
  /* position: sticky; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: solid black 1px;
}

#homeLogoContainer {
  width: 100%;
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home-logo {
  height: 85%;
}

#header h1 {
  font-family: retro;
  font-size: 110px;
}



#chartOptionsContainer {
  height: 75px;
  top: 100px;
  /* position: sticky; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(to top, rgb(255, 236, 23),rgba(255, 236, 23,0)); */
  /* background: #ffec17; */
}

#chartOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 11%;
  width: 100%;
}

#chartOptionsContainer h1 {
  font-size: 30px;
  color: white;
}

#chartSelectionWrapper {
  width: 20%;
  padding-bottom: 2.5%;
  position: relative;
  margin-left: .5%;
  margin-right: .5%;
}

#chartSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 30px;
  background-color:#FFD91B;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.5);
  cursor: default;
}

#chartSelection h1 {
  font-size: 1.7vw;
  color:black;
}

.arrows {
  cursor: pointer;
}

.arrowButton {
  border: none;
  background: none;
  width: 2.5%;
}

#leftNavArrow {
  transform: scaleX(-1);
  width: 100%;
}

#rightNavArrow {
  width: 100%;
}

#dateFormContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background: #ffec17 */
  /* background-image: linear-gradient(to bottom, rgb(255, 236, 23),rgba(255, 236, 23,.8)); */
}

#dateFormWrapper {
  width: 90%;
  padding-bottom: 25%;
  position: relative;
}

#dateForm {
  height: 0%;
  position: absolute;
  background-color:#FFF7E8;;
  padding: 0%;
  border-radius: 30px;
  bottom: 0%;
}

#dateAccentContainer {
  position: absolute;
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px;
}

.dateAccent {
  width: 23%;
  height: 0%;
  border-radius: 30px;
  background-color: black;
}

#dateSelector {
  /* background: rgba(129, 25, 155, .8); */
  /* background: black; */
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 30px;
  position: relative;
}

#dateSearch {
  background: #D60404;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  border-top: solid black 1px;
}


/* #exploreButton {
  background-color: red;
  border-radius: 55px;
  border: 1px solid black;
  box-shadow: 1px 1px 1px black;
  height: 60%;
  width: 25%;
  margin: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

#historyContainer {
  height: 9%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#dayInHistoryButton {
  background-color: #FFD91B;
  border-radius: 30px;
  height: 30px;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.5);
  cursor: pointer;
}

#dayInHistoryButton h1 {
  font-size: 15px;
}

 #searchButton {
  background-color: #723799; 
  border-radius: 30px;
  height: 50px;
  width: 215px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.5);
  cursor: pointer;
}

#searchButton h1{
  font-size: 27px;
  color: white
}

form {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#dd {
  background-color: #723799;
  border: .5px solid rgba(0,0,0,.5);
  margin-right: 1%;
  margin-left: 1%;
}

#mm {
  background-color: #723799;
  border: .5px solid rgba(0,0,0,.5);
}

.mmDD {
  font-size: 13.5vw;
  /* height: 16.5vw; */
  height: 100%;
  width: 28%;
  /* border: solid black; */
  border-radius: 30px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.5);
}

#YYYY {
  font-size: 13.5vw;
  height: 100%;
  width: 43%;
  border-radius: 30px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  background-color: #723799;
  border: .5px solid rgba(0,0,0,.5);
}

input[type="text"] {
  text-align: center;
  outline: none;
  color: white;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

@media only screen and (max-width: 765px) {
  #header h1 {
    /* font-family: retro; */
    font-size: 64px;
  }

  #chartOptionsContainer h1 {
    font-size: 30px;
  }

  #home-logo {
    height: 70%;
  }
}

@media only screen and (max-width: 575px) {

  #home-logo {
    height: 50%;
  }
}

/* Chart Container */

#loadingContainer {
  /* height: 100%; */
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  height: 10vh;
  width: 10vh;
  border-radius: 50%;
  margin: 1%;
  /* background-color: blue; */
}

#chartViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  margin: 0;
  /* height: 100vh; */
  width: 100vw;
}

#chartViewHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  top: 0;
  height: 100px;
  position: sticky;
  padding: 5px;
  width: 100%;
  background-color: #D60404;
  z-index: 2;
  border-bottom: 1px solid black;
}

#chartViewHeader h1 {
font-size: 35px;
color: white;
}

#chartViewHeader h2 {
  font-size: 20px;
  color: white;
  }

/* #headerTitle {
  text-align: left;
} */

#chart-logo {
  height: 100%;
  width: auto;
  cursor: pointer;
}

#headerInfo {
  text-align: right;
  margin-right: .5%;
  cursor: default;
}

#replaceURI {
  
}

#chartComponentContainer {
  display: flex;
  flex-direction: row; 
  justify-content: center;
  width: 100%;
}

#chartContainer {
  width: 50%;
  position: relative;
  /* background-color: pink; */
}
#colorWrap {
  position: relative;
  height: 100%;
  width: 65%;
}

#colorOffsetWrapper {
  width: 65%;
  height: 100%;
  padding: 5px;
  border-radius: 10px;
  position: absolute;
}

#colorOffset {
  background-color: #723799;
  /* width: 65%; */
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: relative;
  box-shadow: 5px 5px 20px 0px rgba(0,0,0,.5);
}

#loading{
  z-index: 2;
  position: absolute;
  top: 0px;
}

/* SONG CARD START */

#songListWrapper {
  padding: 10px 0px 15px 15px;
}

#songCardWrapper {
  position: relative;
  /* margin: 15px; */
  /* margin-left: 2%;
  margin-top: 1%;  */
  background-color: white;
  border-radius: 10px;
  width: 95%;
  height: 0;
  padding-bottom: 10%;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  /* margin-top: .75%; */
  margin-top: 1%;
}

#songCard {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  border: .5px solid rgba(0,0,0,.1);
  border-radius: 10px;
}


#songRankWrapper {
  background-color: #FFD91B;
  width: 15%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 2px solid black; */
  border-radius: 9px 0px 0px 9px;
  border-right: .5px solid rgba(0,0,0,.5);
}

#songRank {
  position: relative;
  border-radius: 50%;
  width: 67%;
  height: auto;
  padding-top: 67%;
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

#songRank h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: white;
  /* font-size: 3vw; */
}

#songInfo {
  background-color: white;
  width: 70%;
  height:100%;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: .5%;
  cursor: default;
}

#songInfoTopAlign {
  position: absolute;
  width: 100%;
  top: 0%;
}

#songInfo h4{
  color: black;
  font-size: 1.2vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

#songInfo h5{
  color: black;
  font-size: .9vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

#songInfo h6{
  color: black;
  font-size: .65vw;
}

#songPlayPause {
  width: 15%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  border-radius: 0px 9px 9px 0px;
  padding: 1%;
  position: relative;
}

.songCardIcon {
  display: flex;
  width: 55%;
  position: absolute;
  right: 10%;
  cursor: pointer;
  background: none;
  border: none;
}

/* BEGIN PLAYER STYLES */

#playerContainer {
  width: 50%;
  height: calc(100vh - 130px);
  top: 115px;
  display: flex;
  justify-content: left;
  overflow-y: auto;
  margin-top: 15px;
  margin-right: 15px;
  position: sticky;
}

#chooseSongContainer {
  height: 100%;
  width: 100%;
}

#chooseSongWrapper {
  width: 80%;
  padding-bottom: 80%;
  margin: 0 auto;
  position: relative;
}

#chooseSong {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#chooseSongSVGContainer {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
}

.bullseye {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

#playerWrapper {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 7px;
  background-color: green;
  display: flex;
  flex-direction: column;
}

#player {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#topPlayerContainer {
  display: flex;
  justify-content: center;
  position: relative;
}

#topPlayerAccent {
  position: absolute;
  height: 20%;
  bottom:0;
  left: 0;
  width: 72%;
  background-color: #C7B6D2;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.1);
}

#artContainer {
  position: relative;
  width: 70%
}

#art {
  width: 84%;
  display: block;
  box-shadow: 0px 6px 15px 0px gray;
  margin-left: auto;
}

#playerPrev {
  width: 50%;
  right: 0;
  position: relative;
  margin-left: auto;
  padding: 10px 0px 5px 0px;
  text-align: right;
  cursor: default;
}

#playerPrev h3 {
  font-size: 1vw;
  text-align: right;
}

#playerControlContainer {
  width: 30%;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin-right: 5px;
}

#playerControlAccent {
  position: absolute;
  width: 50%;
  right:0;
  background-color: #FFD91B;
  height: 100%;
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.1);
}

#playerRank {
  position: relative; 
  z-index: 3;
  width: 60%;
  /* height: 125px; */
  padding-bottom: 60%;
  border-radius: 50%;
  display: flex; 
  justify-content: center;
  align-items: center;
  background-color: black;
  cursor: default;
}

  #playerRank h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    color: white;
    /* font-size: 5.5vw; */
  }

  .playerButton {
    z-index: 3;
    width: 125px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playerButton h1 {
    font-size: 19px;
  }

  .playerContainerIcon {
    width: 45%;
    z-index: 3;
    cursor: pointer;
    background: none; 
    border: none;
  }

  #nowPlayingInfo {
    margin-left: 1%;
    width: 88%;
    cursor: default;
  }

  #nowPlayingIconWrapper {
    width: 10%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #nowPlayingIcon {
    display: flex;
    width: 75%; 
    cursor: pointer;
    background: none;
    border: none;
  }


 

  /* #playPause {
    z-index: 3;
    width: 100px;
    background-color: orange;
  }

  #skip {
    z-index: 3;
    width: 100px;
    background-color: orange;
  }
  
  #save {
    z-index: 3;
    width: 100px;
    background-color: orange;
  } */

#bottomPlayerContainer {
  /* width: 90%; */
  margin-top: 9px;
  width: calc(100% - 5px);
}

#playerSongInfo {
  background-color: white;
  padding: 5px;
  /* white-space: nowrap; */
  border-radius: 10px;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,.25);
  border: .5px solid rgba(0,0,0,.1);
  display: flex;
  justify-content: space-between;
}

#playerSongInfo h1 {
  font-size: 1.8vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
}

#playerSongInfo h2 {
  font-size: 1.1vw;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5;
}


#playerBackground {
  position: absolute;
  height: 100%; 
  width: 40%;
  background-color: blue; 
  left: 23%;
  z-index: 2;
}

/* .artWrapper {
  position: absolute; 
  z-index: 3;
  width: 300px;
  height: 300px;
  background-color: brown; 
  left: 0;
  top: 0;
} */

#artWrapper {
  overflow: hidden;
  /* background-color: white;  */
}







#playerChartInfo {
  position: absolute;
  z-index: 3;
  width:31%;
  /* padding-bottom: 10%; */
  background-color: pink;
  top: 77%;
  left: 43%;
}

#timeoutSVGContainer {
  width: 50%;
}

/* max affects everthing below, min affects everthing above */

@media screen and (min-width: 1200px){
  #homeComponentContainer {
    min-height: 650px;
  }
}

@media screen and (min-width: 1400px){
  #homeComponentContainer {
    min-height: 750px;
  }
}

@media screen and (min-width: 1600px){
  #homeComponentContainer {
    min-height: 800px;
  }
}

@media screen and (min-width: 1800px){
  #homeComponentContainer {
    min-height: 850px;
  }
}

@media screen and (min-width: 2000px){
  #homeComponentContainer {
    min-height: 900px;
  }
}

@media screen and (min-width: 2100px){
  #homeComponentContainer {
    min-height: 1000px;
  }
}

@media screen and (min-width: 2400px){
  #homeComponentContainer {
    min-height: 1050px;
  }
}


@media screen and (max-width: 1400px){
  #rightNavArrow {
    width: 30px
  }
  #leftNavArrow {
    width: 30px
  }
  #chartSelectionWrapper {
    width: auto;
    padding-bottom: 0;
  }
  #chartSelection{
    width: 280px;
    height: 35px;
    position: relative;
  }
  #chartSelection h1{
    font-size: 25px;
  }
}


@media only screen and (max-width: 500px){
  #logInLogoContainer {
    height: 15%;
  }
}

@media only screen and (min-width: 500px){
  #logInLogoContainer {
    height: 20%;
  }
}

@media only screen and (min-width: 1000px){
  #logInLogoContainer {
    height: 30%;
  }

}

@media only screen and (max-width: 900px) {

  #chooseSongContainer {
    display: none;
  }

  #chartViewHeader h1 {
    font-size: 25px;
  }

  #chartViewHeader h2 {
    font-size: 15px;
  }

  #chartComponentContainer {
    flex-direction: column;
  }
  #chartContainer {
    width: 100%;
    height: 60%;
    overflow: hidden;
    /* padding-left: 100px;
    padding-right: 100px; */
  }

  #colorOffsetWrapper {
    width: 100%;
  }


  #playerContainer {
    position: sticky;
    width: 100%;
    height: 100px;
    bottom: 0;
    padding: 0;
    margin-top: 0;
    overflow-y: hidden;
  }
  #player {
    height: 100%;
    width: 100%;
    min-height: 0;
    flex-direction: row;
  }

  /* #nowPlayingIcon {
    width: 75px;
    height: 75px;
    cursor: pointer;
  } */

  #nowPlayingIconWrapper {
    height: 100px;
    width: 100px;
  }

  #nowPlayingInfo h1 {
    font-size: 23px;
  }

  #nowPlayingInfo h2 {
    font-size: 17px;
  }

  /* background-color: white;
width: 70%;
height: 100%;
white-space: nowrap;
position: relative;
display: flex;
flex-direction: column;
justify-content: space-around;
padding-left: .5%; */

  #nowPlayingInfo {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    /* width: calc(100% - 250px); */
  }

  #playerSongInfo {
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: 100px;
    padding: 0;
    justify-content: none;

  }

  #bottomPlayerContainer {
    height: 100px;
    width: 100%;
    margin-top: 0;
    background-color: white;
    width: calc(100% - 150px);
  }

  #topPlayerContainer {
    height: 100px;
    width: auto;
  }

  #topPlayerAccent {
    display: none;
  }

  #playerControlContainer {
    width: 50px;
    height: 100px;
    background-color: #FFD91B;
    justify-content: space-between;
    margin-right: 0;
  }

  /* .playerContainerIcon {
    width: 55%;
  } */

  #playerControlAccent {
    display: none;
  }

  #artContainer {
    height: 100px;
    width: 100px;
    background-color: white;
  }

  #art {
    height: 100px;
    width: 100px;
    box-shadow: none;
  }

  #playerRank {
    display: none;
  }

  #playerPrev {
    display: none;
  }

  #songRank h1 {
    font-size: 5.5vw;
  }

  #songInfo h4 {
    font-size: 2.5vw;
  }

  #songInfo h5 {
    font-size: 2vw;
  }

  #songInfo h6 {
    font-size: 1.5vw;
  }

  #colorOffset {
    width: 50%;
  }

}


/* 404 */

#pageNotFoundContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
}

#pageNotFound {
  position: absolute;
  height: 100%
}

.songcardBirthdayFont {
  font-size: 1.7vw
}

.songcardRankFont {
  font-size: 2.6vw;
}

.playerBirthdayFont {
  font-size: 3.6vw
}

.playerRankFont {
  font-size: 5.5vw;
}


h1, h2, h4, h3, h5, h6 {
  /* margin: 5px; */
}

h1 { font-size: 1em }
h2 { font-size: .9em }
h3 { font-size: .8em }
h4 { font-size: .6em; text-overflow: ellipsis; }
h5 { font-size: .5em }
h6 { font-size: .4em }

/* #songCard {
  margin: 15px;
  background-color: white;
  position: relative;
  border-radius: 3px;
  padding: 3px; 
  width: 90%;
  height: 0;
  padding-bottom: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

/* #songWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
} */

/* #songCardArtistInfo {
  display: block;
  overflow: hidden;
  white-space: nowrap;
} */

/* #songCardChartInfo {
  display: flex;
  flex-direction: row;
} */

/* #songCardPlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  background-color: skyblue
} */